import { ElNotification } from 'element-plus'

// 密码校验
const pswdReg = (pswd) => {
    const pwd_reg = /^(?=.*\d)(?=.*[a-z])[a-zA-Z0-9!@#$%_|`]{6,12}$/
    return pwd_reg.test(pswd)
}

const getNowDate = () => {
    let nowdata = new Date();
    let year = nowdata.getFullYear();
    let month = nowdata.getMonth() + 1;
    month = month < 10 ? "0" + month : month;
    let date = nowdata.getDate();
    date = date < 10 ? "0" + date : date;
    let h = nowdata.getHours();
    h = h < 10 ? "0" + h : h;
    let m = nowdata.getMinutes();
    m = m < 10 ? "0" + m : m;
    let s = nowdata.getSeconds();
    s = s < 10 ? "0" + s : s;

    let nowtime =
        year + "-" + month + "-" + date + " " + h + ":" + m + ":" + s;
    // console.log("11111111", nowtime);
    return nowtime
}

// 复制到粘贴板 传入容器id
const copy = async (text, toast) => {
    ElNotification.closeAll();
    let codeId = document.getElementById(text);
    const selectValue = codeId.innerHTML.replace(/<[^>]*>/g, '');
    const fallbackCopyTextToClipboard = (selectValue, toast) => {
        let textArea = document.createElement("textarea");
        textArea.value = selectValue;

        // Avoid scrolling to bottom
        textArea.style.top = "-200";
        textArea.style.left = "0";
        textArea.style.position = "fixed";
        textArea.style.opacity = "0"

        document.body.appendChild(textArea);
        // textArea.focus();
        textArea.select();
        let msg;
        try {
            let successful = document.execCommand("copy");
            msg = successful ? toast ? toast : "复制成功" : "复制失败";
        } catch (err) {
            msg = "复制失败";
        }
        ElNotification({
            duration: 2000,
            title: "信息提示：",
            message: msg,
            position: 'top-right',
            customClass: 'notification',
        })
        document.body.removeChild(textArea);
    };

    const copyTextToClipboard = (text, toast) => {
        if (!navigator.clipboard || !window.isSecureContext) {
            fallbackCopyTextToClipboard(text, toast);
            return;
        }
        navigator.clipboard
            .writeText(text)
            .then(() => {
                ElNotification({
                    duration: 2000,
                    title: "信息提示：",
                    message: toast ? toast : "复制成功",
                    position: 'top-right',
                    customClass: 'notification',
                })
            })
            .catch(() => {
                fallbackCopyTextToClipboard(text, toast)
            });
    };
    copyTextToClipboard(selectValue, toast);
};
// 复制到粘贴板 传入复制的值
const copy1 = async (selectValue, toast) => {
    ElNotification.closeAll();
    const fallbackCopyTextToClipboard = (selectValue, toast) => {
        let textArea = document.createElement("textarea");
        textArea.value = selectValue;

        // Avoid scrolling to bottom
        textArea.style.top = "-200";
        textArea.style.left = "0";
        textArea.style.position = "fixed";
        textArea.style.opacity = "0"

        document.body.appendChild(textArea);
        // textArea.focus();
        textArea.select();
        let msg;
        try {
            let successful = document.execCommand("copy");
            msg = successful ? toast ? toast : "复制成功" : "复制失败";
        } catch (err) {
            msg = "复制失败";
        }
        ElNotification({
            duration: 2000,
            title: "信息提示：",
            message: msg,
            position: 'top-right',
            customClass: 'notification',
        })
        document.body.removeChild(textArea);
    };

    const copyTextToClipboard = (text, toast) => {
        if (!navigator.clipboard || !window.isSecureContext) {
            fallbackCopyTextToClipboard(text, toast);
            return;
        }
        navigator.clipboard
            .writeText(text)
            .then(() => {
                ElNotification({
                    duration: 2000,
                    title: "信息提示：",
                    message: toast ? toast : "复制成功",
                    position: 'top-right',
                    customClass: 'notification',
                })
            })
            .catch(() => {
                fallbackCopyTextToClipboard(text, toast)
            });
    };
    copyTextToClipboard(selectValue, toast);
};
// 动画
const explode = (box, s = '1') => {
    // let boxColor = window.getComputedStyle(box).backgroundColor;
    box.style.animationName = 'fadeOut';
    box.style.animationDuration = s + 's';
    box.style.animationFillMode = 'forwards';
    box.style.position = 'relative'
    setTimeout(() => {
        box.style.animationName = 'fadeIn';
        box.style.animationDuration = s + 's';
        box.style.animationFillMode = 'forwards';
    }, 500)
    // 获取盒子的位置和大小
    var boxRect = box.getBoundingClientRect();
    // var boxX = boxRect.left + boxRect.width / 2;
    // var boxY = boxRect.top + boxRect.height / 2;
    var boxWidth = boxRect.width;
    var boxHeight = boxRect.height;

    // 创建画布元素
    var canvas = document.createElement('canvas');
    canvas.width = boxWidth;
    canvas.height = boxHeight;
    // canvas.style.position = 'absolute';
    canvas.style.position = 'fixed';
    canvas.style.left = boxRect.left + 'px';
    canvas.style.top = boxRect.top + 'px';
    document.body.appendChild(canvas);

    // 获取画布上下文
    var ctx = canvas.getContext('2d');

    // 创建像素块数组
    var pixels = [];
    var pixelCount = 200;
    for (var i = 0; i < pixelCount; i++) {
        var pixel = {
            x: boxWidth / 2,
            y: boxHeight / 2,
            size: Math.floor(Math.random() * 16) + 10,
            color: 'rgb(' + Math.floor(Math.random() * 256) + ',' + Math.floor(Math.random() * 256) + ',' + Math.floor(Math.random() * 256) + ')',
            // color: boxColor,
            velocity: {
                x: Math.random() * 10 - 5,
                y: Math.random() * 10 - 5
            },
            acceleration: {
                x: Math.random() * 2 - 1,
                y: Math.random() * 2 - 1
            },
            opacity: 1
        };
        pixels.push(pixel);
    }

    // 更新像素块的位置和速度，并绘制到 Canvas 上
    const updatePixels = () => {
        // 清除画布
        ctx.clearRect(0, 0, boxWidth, boxHeight);

        // 遍历每个像素块
        for (var i = 0; i < pixels.length; i++) {
            var pixel = pixels[i];

            // 更新像素块的速度和位置
            pixel.velocity.x += pixel.acceleration.x;
            pixel.velocity.y += pixel.acceleration.y;
            pixel.x += pixel.velocity.x;
            pixel.y += pixel.velocity.y;

            // 绘制像素块
            ctx.fillStyle = pixel.color;
            ctx.globalAlpha = pixel.opacity;
            ctx.fillRect(pixel.x - pixel.size / 2, pixel.y - pixel.size / 2, pixel.size, pixel.size);

            // 渐隐效果
            if (pixel.opacity > 0) {
                pixel.opacity -= 0.01;
            } else {
                pixels.splice(i, 1);
                i--;
            }
        }

        // 动画循环
        if (pixels.length > 0) {
            requestAnimationFrame(updatePixels);
        } else {
            document.body.removeChild(canvas);
        }
    }

    // 开始动画循环
    updatePixels();
}

// 匹配 label
const matchLabel = (val, list) => {
    let name = "";
    list.forEach((item) => {
        if (item.value == val) {
            name = item.label;
        }
    });
    return name;
}
// 匹配 description
const matchDescription = (id, list) => {
    let name = "";
    list.forEach((item) => {
        if (item.id == id) {
            name = item.description;
        }
    });
    return name;
}
/**
 * 四舍五入
 * @param num
 * @param decimalPlaces{小数位}
 * @returns {number}
 */
const fiveRound = (num, decimalPlaces) => {
    var d = decimalPlaces || 0;
    return Math.round(num * Math.pow(10, d)) / Math.pow(10, d);
}

/**
 * 四舍六入
 * @param num
 * @param decimalPlaces{小数位}
 * @returns {number}
 */
const sixRound = (num, decimalPlaces) => {
    var d = decimalPlaces || 0;
    var m = Math.pow(10, d);
    var n = +(d ? num * m : num).toFixed(8);
    var i = Math.floor(n), f = n - i;
    var e = 1e-8;
    var r = (f > 0.5 - e && f < 0.5 + e) ?
        ((i % 2 == 0) ? i : i + 1) : Math.round(n);
    return d ? r / m : r;
}

/**
 * 小数位不够自动补 “0”
 * @param num {数值}
 * @param len {保留几位小数}
 */
const numDecimal = (num, len) => {
    num = Number(((num || 0).toString().trim() || 0));
    len = Number(((len || 0).toString().trim() || 0));
    if (len == 0) return String(num).split(".")[0];
    if (num.toString().indexOf(".") == -1 && len != 0) {
        num = Number((num + ".0"));
    }
    var split = num.toString().split(".");
    var decimal = split[1].toString();
    num = Number(split[0].toString());
    if (decimal.length > len) {
        decimal = decimal.substring(0, len);
    } else {
        while (decimal.length != len) {
            decimal += "0";
        }
    }
    return num + "." + decimal;
}

/**
* 修约精度
* @param num{数值}
* @param len{精度（1、0.5、5、10、0.1、0.01）}
* @param even{修约（四舍五入、四舍六入）}
* @returns {number}
*/
const cpEvenRound = (num, len, even) => {
    try {
        if (isNaN(Number(num))) {
            return num;
        }
        num = Number(numDecimal(num, 10));
        var d = ((len || 0).toString().trim() || 0);
        var rule = (even != undefined && (even.toString().indexOf("5") != -1 || even.toString().indexOf("五") != -1)) ? 5 : 6;
        if (len == 0.5) {
            //TODO 精度为 0.5 时
            //(0.75 * 2).修约 / 2
            if (rule == 5)
                num = fiveRound(num * 2, 0) / 2;//四舍五入
            else
                num = sixRound(num * 2, 0) / 2;//四舍六入
            //小数位自动补 "0"
            num = Number(numDecimal(num, 1));
        } else if (len == 5) {
            //TODO 精度为 ”5“ 时 ，个位只会是（0 | 5）
            //(0.75 * 0.2).修约 / 0.2
            if (rule == 5)
                num = fiveRound(num * 0.2, 0) / 0.2;//四舍五入
            else
                num = sixRound(num * 0.2, 0) / 0.2;//四舍六入
            //小数位自动补 "0"
            num = Number(numDecimal(num, 0));
        } else if (len == 10) {
            //TODO 精度为 ”10“ 时
            //(0.75 / 10).修约 * 10
            if (rule == 5)
                num = fiveRound(num / 10, 0) * 10;//四舍五入
            else
                num = sixRound(num / 10, 0) * 10;//四舍六入
        } else {
            //小数点后有几位，就保留几位小数,例：0.1、0.01
            if (len != undefined && len.toString().split(".").length > 1)
                d = len.toString().split(".")[1].length;
            else d = 0;
            if (rule == 5)
                num = fiveRound(num, d);
            else
                num = sixRound(num, d);
            //小数位自动补 "0"
            num = Number(numDecimal(num, d));
        }
    } catch (e) {
        // console.log("修约错误：" + e.message);
    }
    // console.log("修约结果：" + num);
    return num;
}

export default {
    pswdReg, // 密码校验
    getNowDate, // 获取当前时间
    copy, // 复制
    copy1, // 复制
    explode, // 破碎像素块动画
    matchLabel, // 匹配label
    matchDescription, // 匹配description
    cpEvenRound, // 修约精度
    numDecimal, // 小数位不够自动补 “0”
}