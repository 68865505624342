import { createRouter, createWebHashHistory } from 'vue-router';
// import { ElMessage } from 'element-plus';
import Home from '../views/index'

//路由路径的定义
const routes = [
    {
        path: '/',
        name: 'Home',
        redirect: '/fragment',
        component: Home,
        children: [
            {
                path: '/fragment',
                name: 'Fragment',
                meta: {
                    title: '代码',
                },
                component: () => import('../views/code-fragment.vue'),
            },
            {
                path: '/daohang',
                name: 'Daohang',
                meta: {
                    title: '导航',
                },
                component: () => import('../views/daohang.vue'),
            }, {
                path: '/fangfa',
                name: 'Fangfa',
                meta: {
                    title: '方法',
                },
                component: () => import('../views/fangfa.vue'),
            },
            {
                path: '/gongju',
                name: 'Gongju',
                meta: {
                    title: '工具',
                },
                component: () => import('../views/gongju.vue'),
            }, {
                path: '/chajian',
                name: 'Chajian',
                meta: {
                    title: '插件',
                },
                component: () => import('../views/chajian.vue'),
            }, {
                path: '/rili',
                name: 'Rili',
                meta: {
                    title: '日历',
                },
                component: () => import('../views/domeComponents/rili.vue'),
            }, {
                path: '/rili1',
                name: 'Rili1',
                meta: {
                    title: '日历',
                },
                component: () => import('../views/domeComponents/rili1.vue'),
            }, {
                path: '/tupian',
                name: 'Tupian',
                meta: {
                    title: '图片',
                },
                component: () => import('../views/domeComponents/tupian.vue'),
            },
            {
                path: '/mymap-point',
                name: 'MyMapPoint',
                meta: {
                    title: '离线地图-打点',
                },
                component: () => import('../views/domeComponents/mymap-point.vue'),
            },
            {
                path: '/mymap-route1',
                name: 'MyMapRoute1',
                meta: {
                    title: '离线地图-轨迹回放',
                },
                component: () => import('../views/domeComponents/mymap-route2.vue'),
            },
            {
                path: '/mymap-route',
                name: 'MyMapRoute',
                meta: {
                    title: '离线地图-定时器-轨迹回放',
                },
                component: () => import('../views/domeComponents/mymap-route1.vue'),
            },
            {
                path: '/mymap-draw',
                name: 'MyMapDraw',
                meta: {
                    title: '离线地图-电子围栏',
                },
                component: () => import('../views/domeComponents/mymap-draw.vue'),
            },
        ],
    },

    {
        path: '/login',
        name: 'Login',
        meta: {
            title: '登录',
        },
        component: () => import('../views/login.vue'),
    },
    {
        path: '/404',
        component: () => import('../components/error-page.vue'),
        hidden: true
    },

    //这个*匹配必须放在最后，将改路由配置放到所有路由的配置信息的最后，否则会其他路由path匹配造成影响。     
    {
        path: '/:pathMatch(.*)',
        redirect: '/404',
        hidden: true
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

// 路由拦截
router.beforeEach((to) => {
    document.title = to.meta.title;
})
// router.beforeEach((to, from, next) => {
// const expir_time = localStorage.getItem('expir_time');
//  未登入,并且不是进入的登入页面
// if (to.path !== '/login') {
//     next('/login');
// } else if (new Date().getTime() > Number(expir_time)) {//判断登录过期
//     localStorage.removeItem('Authorization');
//     localStorage.removeItem('expir_time');
//     ElMessage('当前登录已过期,请重新登录!!');
//     next('/login?state=0');
// } else if (to.path == '/fangfa') {//直接跳转到首页
//     next()
// } else { // 放行,并重置过期时间
//     next();
// }
// });

export default router;
