import axios from 'axios';
import router from '../router';
import { ElMessage } from "element-plus";

const service = axios.create({
    timeout: 200000,
    baseURL: '/baseUrl'
});

service.interceptors.request.use(
    (config) => {
        console.log(config, 'config');
        return config;
    },
    (error) => {
        console.log(error);
        return Promise.reject();
    }
);

service.interceptors.response.use(
    (response) => {
        if (response.data.code === 401) {
            router.push({ path: "/login" });
            ElMessage({
                type: 'warning',
                message: response.data.msg || response.data.message
            })
        }

        if (response.status === 200) {
            return response;
        } else {
            Promise.reject();
        }
    },
    (error) => {
        console.log(error);
        return Promise.reject();
    }
);

export default service;
