import { createApp } from 'vue'
import App from './App.vue'
const app = createApp(App);

import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import 'element-plus/dist/index.css';
import ElementPlus from 'element-plus'

import VueLazyLoad from 'vue-lazyload';
app.use(VueLazyLoad, {
    // 可选配置项
    error: require('./assets/img/error.jpg'), // 加载失败时显示的图片
    loading: require('./assets/img/loading.gif'), // 加载中时显示的图片
    preLoad: 1.3, // 预加载高度的比例
    attempt: 3 // 尝试加载次数
})

import router from './router';
app.use(router);
app.use(ElementPlus)
// 注册elementplus图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
}
app.mount('#app')
