import request from '../utils/request'
import requestProxy from '../utils/requestProxy'

/**
 * 一言请求
 * @returns 
 */
export const oneSpeech = () => {
    return request({
        headers: {
            contentType: "application/json; charset=UTF-8",
            type: 'json'
        },
        url: 'https://v1.hitokoto.cn/?c=a&c=b&c=c&c=d&c=e&c=i&c=j&c=k&encode=json',
        method: 'get',
        // params: params

    })
}

// 文件上传解析
export const uploadFileJx = (data) => {
    return requestProxy({
        // headers: {
        //     contentType: 'multipart/form-data'
        // },
        url: '/excelUpload',
        method: 'post',
        data
    })
}

// 文件下载
export const downloadFileJx = () => {
    return requestProxy({
        // headers: {
        //     contentType: 'multipart/form-data'
        // },
        url: '/excelData',
        method: 'get',
    })
}

// 文件删除
export const deleteFileJx = (data) => {
    return requestProxy({
        // headers: {
        //     contentType: 'multipart/form-data'    
        // },
        url: '/excelDelete?fileName=' + data,
        method: 'delete'
    })
}