<template>
	<div class="header shadow-md">
		<div class="logo">
			<img src="https://tools.yuaq.cn/img/favicon.ico" width="40" />
			<span style="vertical-align:top;padding-left:5px">{{ projectName }}</span>
		</div>
		<ul class="nav-box">
			<li class="btnBox up-float" v-for="item in menuList" :key="item.path"><a :href="item.path"
					:target="item.target">{{
				item.title
			}}</a></li>
		</ul>
		<div class="music-box">{{ dateTime }}</div>
		<div class="header-right">
			<div class="header-user-con" v-if="isLogin">
				<!-- 用户头像 -->
				<el-avatar class="user-avator" :size="40"
					src="https://www.yuaq.cn/usr/themes/handsome/assets/img/avatar.jpg" />
				<!-- 用户名下拉菜单 -->
				<el-dropdown class="user-name" trigger="click" @command="handleCommand">
					<span class="el-dropdown-link">
						{{ nickname }}
						<el-icon class="el-icon--right">
							<arrow-down />
						</el-icon>
					</span>
					<template #dropdown>
						<el-dropdown-menu>
							<el-dropdown-item command="user">离线地图</el-dropdown-item>
							<el-dropdown-item divided command="loginout">退出登录</el-dropdown-item>
						</el-dropdown-menu>
					</template>
				</el-dropdown>
			</div>
			<div v-else>
				<el-dropdown class="login-form" trigger="click" @command="loginCommand">
					<span class="el-dropdown-link">
						登录
						<el-icon class="el-icon--right">
							<arrow-down />
						</el-icon>
					</span>
					<template #dropdown>
						<el-dropdown-menu>
							<el-input v-model="userInfo.username" placeholder="用户名" />
							<el-input v-model="userInfo.pswd" placeholder="密码" show-password />
							<el-dropdown-item divided command="login" class="login-btn">登录</el-dropdown-item>
						</el-dropdown-menu>
					</template>
				</el-dropdown>
			</div>
		</div>
	</div>
</template>
<script setup>

import { ref, watch, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { ElMessageBox, ElMessage } from 'element-plus';
import publicFun from '@/utils/publicFun';

// 定时器更新时间

const dateTime = ref(null)
onMounted(() => {
	setInterval(() => {
		dateTime.value = publicFun.getNowDate()
	}, 1000)
})

const menuList = ref([
	{
		path: '/',
		title: '首页',
		target: '_self'
	}
])
// 是否处于登录状态
const isLogin = ref(localStorage.getItem('isLogin') || false)
watch(() => isLogin.value, (newValue) => {
	console.log(newValue);
	if (newValue) {
		menuList.value = [
			{
				path: '/',
				title: '首页',
				target: '_self'
			}, {
				path: 'http://121.36.215.1',
				title: '博客',
				target: '_blank'
			}, {
				path: 'http://img.yuaq.cn/',
				title: '图床',
				target: '_blank'
			}, {
				path: 'http://jianli.yuaq.cn/',
				title: '关于',
				target: '_blank'
			}
		]
	} else {
		menuList.value = [
			{
				path: '/',
				title: '首页',
				target: '_self'
			}
		]
	}
}, { immediate: true })
// 判断是否为空值 ⬇⬇⬇
const isNullValue = (value) => {
	return [null, undefined, ''].includes(value)
};

const userInfo = ref(JSON.parse(localStorage.getItem('userInfo')) || { username: "", pswd: "" });
console.log(userInfo.value);
const loginCommand = () => {
	if (isNullValue(userInfo.value.username) || isNullValue(userInfo.value.pswd)) {
		ElMessage({
			message: '用户名或密码不能为空',
			type: 'warning',
		})
		return false
	} else {
		if (publicFun.pswdReg(userInfo.value.pswd) === false) {
			ElMessage({
				message: '密码格式错误',
				type: 'warning',
			})
			return false
		} else {
			// 登录的逻辑
			// 假设登录成功，将 isLogin 设置为 true
			localStorage.setItem('isLogin', true)
			localStorage.setItem('userInfo', JSON.stringify(userInfo.value))
			isLogin.value = true
			ElMessage({
				message: '登录成功',
				type: 'success',
			})
			router.push('/')
			return true
		}
	}

}

// 用户名下拉菜单选择事件
const router = useRouter();
const projectName = ref('工具')
const nickname = ref('林安') // 用户昵称
const handleCommand = (command) => {
	if (command == 'loginout') {
		ElMessageBox.confirm(
			'是否确认退出?',
			'提示',
			{
				confirmButtonText: '确认',
				cancelButtonText: '取消',
				type: 'warning',
			}
		)
			.then(() => {
				localStorage.setItem('isLogin', false)
				isLogin.value = false
				ElMessage({
					type: 'success',
					message: '退出登录成功！',
				})
				// localStorage.removeItem('ms_username');
				router.push('/');
			})
			.catch(() => {
				ElMessage({
					type: 'info',
					message: '取消退出！',
				})
			})

	} else if (command == 'user') {
		// 在新窗口打开用户信息页面
		// let href = router.resolve({ path: '/mymap-point' }); // 地图打点
		// let href = router.resolve({ path: '/mymap-route' });  // 轨迹回放
		let href = router.resolve({ path: '/mymap-draw' });  // 电子围栏
		window.open(href.href, '_blank');
	}
};
</script>

<style scoped lang="less">
@import url('../assets/css/basiceStyle.less');

.header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9;
	display: flex;
	justify-content: space-between;
	box-sizing: border-box;
	width: 100%;
	height: 100px;
	padding: 0.625rem 0;
	box-sizing: border-box;
	font-family: Arial, sans-serif;
	font-size: 16px;
	line-height: 1.5;
	background: @theme-color;
	color: #fff;
}

.logo {
	display: flex;
	align-items: center;
	padding-left: 50px;
	font-size: 1.75rem;
	color: #fff;
	font-family: 'STXingkai';
	text-align: center;

	span {
		overflow: hidden; // 溢出隐藏
		white-space: nowrap; // 不换行
		text-overflow: ellipsis; // 溢出显示省略号
	}
}

.nav-box {
	display: flex;
	align-items: center;

	li {
		margin-left: 2.25rem;
		font-size: 1.25rem;
		font-weight: 500;
		width: 8rem;
		text-align: center;
		box-shadow: -5px 5px 10px @theme-color1, 5px -5px 10px @theme-color1;

		a {
			display: block;
			width: 100%;
		}

		&:nth-child(1) {
			margin-left: 0;
		}

		&:hover {
			box-shadow: -5px 5px 10px @theme-color2, 5px -5px 10px @theme-color1;
		}
	}

}

.music-box {
	display: flex;
	align-items: center;
	padding-top: 0;
	width: 20%;
	height: 100%;
	font-size: 36px;
	font-family: DSdigi;
	color: #57947f;
	background: transparent;
	// background: skyblue;

	.aplayer {
		margin: 0;
		z-index: 3;
	}

	.aplayer-list li {
		color: #000000;
	}

	.aplayer .aplayer-lrc.aplayer-lrc-hide {
		display: block;
	}

	.aplayer-title {
		color: #000000;
	}

	.aplayer .aplayer-info {
		padding: 5px 0 0 5px !important;
	}

	.aplayer .aplayer-icon {
		display: inline-block;
	}

	.aplayer-music {
		height: 25px !important;
	}

	.aplayer-notice {
		opacity: 1;
	}
}

.header-right {
	padding-right: 50px;
}

.header-user-con {
	display: flex;
	height: 70px;
	align-items: center;
}

.btn-fullscreen {
	transform: rotate(45deg);
	margin-right: 5px;
	font-size: 24px;
}

.btn-bell,
.btn-fullscreen {
	position: relative;
	width: 30px;
	height: 30px;
	text-align: center;
	border-radius: 15px;
	cursor: pointer;
	display: flex;
	align-items: center;
}

.btn-bell-badge {
	position: absolute;
	right: 4px;
	top: 0px;
	width: 8px;
	height: 8px;
	border-radius: 4px;
	background: #f56c6c;
	color: #fff;
}

.btn-bell .el-icon-lx-notice {
	color: #fff;
}

.user-name {
	margin-left: 10px;
}

.user-avator {
	margin-left: 20px;
}

.el-dropdown-link {
	color: #fff;
	cursor: pointer;
	display: flex;
	align-items: center;
}

.el-dropdown-menu__item {
	text-align: center;
}

.login-form {
	font-size: 18px;
	margin-top: 30%;
	padding-right: 50px;
}
</style>
