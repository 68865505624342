import axios from 'axios';

const service = axios.create({
    timeout: 150000,
    baseURL: "/api",
    headers: {
        'Content-Type': 'multipart/form-data'
    }
});


export default service;
