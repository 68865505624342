<template>
  <div class="sidebar shadow-md rounded">
    <!-- 菜单 -->
    <el-menu class="sidebar-el-menu" :default-active="onRoutes" :background-color="backgroundColor"
      :text-color="textColor" :active-text-color="activeColor" router>
      <template v-for="item in menuList">
        <!-- 多级 -->
        <template v-if="item.childern">
          <el-sub-menu :index="item.index" :key="item.index">
            <template #title>
              <el-icon>
                <component :is="item.icon"></component>
              </el-icon>
              <span>{{ item.title }}</span>
            </template>
            <!-- 二级 -->
            <el-menu-item v-for="subItem in item.childern" :index="subItem.index" :key="subItem.index">
              {{ subItem.title }}
            </el-menu-item>
          </el-sub-menu>
        </template>
        <!-- 单级 -->
        <template v-else>
          <el-menu-item :index="item.index" :key="item.index">
            <el-icon>
              <component :is="item.icon"></component>
            </el-icon>
            <template #title>{{ item.title }}</template>
          </el-menu-item>
        </template>
      </template>

      <!-- <el-menu-item :index="item.index" v-for="item in menuList" :key="item.index">
        <el-icon>
          <component :is="item.icon"></component>
        </el-icon>
        <template #title>{{ item.title }}</template>
      </el-menu-item> -->
    </el-menu>
    <!-- 一言 -->
    <div class="aside-bottom" id="wenzhai" @click="getAndanimation('wenzhai', 0.5)">
      <p @click.stop="getAndanimation('wenzhai', 0.5)">&nbsp;&nbsp;&nbsp;&nbsp;
        <em id="yiyan">{{ yiyan }}</em>
      </p> <span><a href="https://hitokoto.cn/#" target="_blank">—— 一言</a></span>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useRoute } from "vue-router";
import { oneSpeech } from '../api'
import publicFun from '@/utils/publicFun'
import { ElNotification } from 'element-plus'

const menuList = ref([
  {
    icon: 'Tickets',
    index: '/fragment',
    title: '代码片段',
  },
  {
    icon: 'Star',
    index: '/daohang',
    title: '常用文档',
  },
  // {
  //   icon: 'EditPen',
  //   index: '/fangfa',
  //   title: '开发方法',
  // },
  {
    icon: 'Guide',
    index: '/gongju',
    title: '日常工具',
  },
  {
    icon: 'CirclePlus',
    index: '/chajian',
    title: '实用插件',
  },
  {
    icon: 'Grid',
    index: '/anLi',
    title: '案例',
    childern: [
      {
        icon: 'Map',
        index: '/mymap-point',
        title: '地图打点',
      },
      {
        icon: 'Map',
        index: '/mymap-route',
        title: '轨迹回放',
      },
      {
        icon: 'Map',
        index: '/mymap-draw',
        title: '电子围栏',
      },
      {
        icon: 'Calendar',
        index: '/rili',
        title: '日历组件',
      }, {
        icon: 'Picture',
        index: '/tupian',
        title: '图片',
      },
    ]
  },

]);


const yiyan = ref(localStorage.getItem('yiyan') ?? '在心里种花，人生才不会荒芜')
// 请求+动画
const getAndanimation = async (id, s) => {
  ElNotification.closeAll();
  // 根据传入的id找到对应的盒子元素
  let box = document.getElementById(id);
  console.log(box.getBoundingClientRect());
  // 设置盒子的爆炸动画效果
  publicFun.explode(box, s)

  // 检查本地存储中的请求时间戳
  const lastRequestTime = localStorage.getItem('lastRequestTime') ?? null;
  if (lastRequestTime) {
    const currentTime = new Date().getTime();
    const elapsedTime = currentTime - Number(lastRequestTime);
    const hoursSinceLastRequest = Math.floor(elapsedTime / (1000 * 60 * 60));
    if (hoursSinceLastRequest < 12) {
      ElNotification({
        duration: 3000,
        title: "请求提示：",
        message: "十二小时内只能请求一次",
        position: 'top-right',
        customClass: 'notification',
      })
      yiyan.value = localStorage.getItem('yiyan')
      return;
    }
  }

  // 在动画结束后执行消失操作
  setTimeout(async () => {
    // 赋值文字盒子
    oneSpeech().then(res => {
      if (res.status == 200) {
        yiyan.value = res.data.hitokoto
        // 更新本地存储中的请求时间戳
        localStorage.setItem('lastRequestTime', new Date().getTime().toString());
        localStorage.setItem('yiyan', res.data.hitokoto);
      }
    })

  }, s * 1000);

}

const route = useRoute();
const onRoutes = computed(() => {
  // console.log(route, 'route.....');
  return route.path;
});

//左侧navbar颜色定义
const textColor = "#fff";
const activeColor = "#e6eef4";
const backgroundColor = 'transparent'
</script>

<style scoped lang="less">
@import '../assets/css/basiceStyle.less';

.sidebar {
  position: fixed;
  left: 5%;
  top: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 16%;
  height: 83vh;
  padding: 1.25rem 0;
  margin-right: 15px;
  color: #fff;
  background: transparent;
  box-sizing: border-box;
  overflow-y: scroll;
  background: @theme-color;

  :deep .el-menu {
    height: 60%;
    overflow: hidden !important;
    overflow-y: auto !important;

    &::-webkit-scrollbar {
      display: none;
      scrollbar-width: none;
      -ms-overflow-style: none;
    }
  }

  .aside-bottom {
    height: 35%;
    width: 92%;
    padding: 0 0.625rem;
    margin: 0 auto;
    z-index: 99;
    color: @theme-color2;
    font-size: 1.25rem;
    font-family: 'STXingkai';
    box-sizing: border-box;
    border: 1px solid @theme-color;
    border-radius: 4px;
    background: @theme-color;
    cursor: pointer;
    box-shadow: -5px 5px 10px @theme-color2,
      5px -5px 10px @theme-color1;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE 10+ */
    user-select: none;

    /* Standard syntax */
    p {
      width: 100%;
      display: block;
      max-height: 70%;
      overflow: hidden;
      word-break: break-al1;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 6;
    }

    span {
      width: 100%;
      display: flex;
      justify-content: end;

      a {
        color: #1dbe40;
      }
    }
  }
}

:deep .el-sub-menu .el-sub-menu__title * {
  font-size: 18px !important;
}

:deep .el-sub-menu ul>li.el-menu-item {
  font-size: 16px;
}

:deep .el-sub-menu.is-active {
  color: #65ECC1;
  background-image: linear-gradient(to right, #B6FAEB, #65ECC1);

  .el-menu {
    background: @theme-color !important;
  }
}

:deep .el-menu-item {
  // position: relative;
  width: 100%;
  // color: @theme-color1;
  color: #fff;
  font-size: 18px;
  transition: all 500ms;

  &:hover {
    color: #2c9caf;
    transform: scale(1.1);
    transform: translateX(50%);
    z-index: 9;
    border-radius: 0.25rem;
    border-left: 1px solid #2c9caf;
    box-shadow: 4px 5px 17px -4px #268391;
  }

  &::before {
    content: "";
    position: absolute;
    left: -50%;
    top: 0;
    width: 0;
    height: 100%;
    // background-color: #2c9caf;
    // background-color: @theme-color;
    background-image: linear-gradient(to right, #B6FAEB, #65ECC1);
    transform: skewX(45deg);
    z-index: -1;
    transition: width 1000ms;
  }

  &::after {
    content: "";
    position: absolute;
    left: -990%;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, #B6FAEB, #D9FCF6, #EAFCFB);
    transform: translateZ(100px);
    transform: skewX(45deg);
    z-index: -1;
    transition: width 1000ms;
  }

  &:hover {
    &::before {
      left: -10%;
      width: 250%;
    }

    &::after {
      left: -110%;
      width: 100%;
    }
  }

}

:deep .el-menu-item.is-active {
  display: flex;
  justify-content: center;
  color: #65ECC1;
  background-color: #2c9caf;

  &:hover {
    color: #2c9caf;
    transform: translateX(0);
  }
}

.sidebar::-webkit-scrollbar {
  width: 0;
}

.sidebar>ul {
  height: 100%;
}
</style>
<style lang="less">
/* 通知提示框 */
.notification {
  border: none;
  background-image: linear-gradient(to right, #B6FAEB, #65ECC1);
}
</style>